<template>
    <div class="container-fluid">
        <h1>{{$t('users.heading')}}</h1>
        <p>{{$t('users.subtitle')}}</p>

        <div class="row mt-4 justify-content-between">
            <div class="col-12 col-lg-5 col-xl-4 mb-4">
                <h3>Přidat / upravit uživatele</h3>
                <div class="p-4 rounded border border-gray bg-white shadow">
                    <loader-overlay
                        :loading="userSaving || brokersLoading"
                    >
                        <user-form
                            :user="editedUser"
                            :brokers="izdocsBrokersEnum"
                            @submit="onSubmit"
                            @cancel="editedUser = null"
                        />
                    </loader-overlay>
                </div>
            </div>
            <div class="col-12 col-xl-8">
                <h3>Seznam uživatelů</h3>
                <users-data-table
                    :dataset="users"
                    :loading="usersLoading"
                    :brokers="izdocsBrokersEnum"
                    @edit="onEdit"
                    @remove="onRemove"
                    @impersonate="onImpersonate"
                />
            </div>
        </div>
    </div>
</template>

<script>
import LoaderOverlay from '../../../../Components/LoaderOverlay.vue'
import UserForm from './Components/UserForm.vue'
import UsersDataTable from './Components/UsersDataTable.vue'

export default {
    name: 'UsersPage',
    components: {
        UsersDataTable,
        UserForm,
        LoaderOverlay
    },
    data () {
        return {
            users: [],
            izdocsBrokersEnum: [],
            brokersLoading: true,
            usersLoading: true,
            userSaving: false,
            editedUser: null
        }
    },
    mounted () {
        this.loadUsers()
        this.loadBrokers()
    },
    methods: {
        onSubmit (userData) {
            this.userSaving = true
            const apiCall = (!userData._id) ? () => this.$api.users.create(userData) : () => this.$api.users.update(userData._id, userData)
            apiCall()
                .then(response => {
                    this.$notify.success('Uživatel byl uložen.')
                    this.loadUsers()
                    this.editedUser = null
                })
                .catch(error => {
                    console.error(error)
                    this.$notify.error('Nepodařilo se uložit uživatele.')
                })
                .finally(() => {
                    this.$nextTick(() => {
                        this.userSaving = false
                    })
                })
        },
        loadUsers () {
            this.usersLoading = true
            this.$api.users.read()
                .then((response) => {
                    this.users = [...response.data]
                }).catch((error) => {
                    console.error(error)
                    this.$notify.error('Nepodařilo se načíst seznam uživatelů.')
                }).finally(() => {
                    this.$nextTick(() => {
                        this.usersLoading = false
                    })
                })
        },
        loadBrokers () {
            this.brokersLoading = true
            this.$api.izdocs.getBrokersEnum()
                .then((response) => {
                    this.izdocsBrokersEnum = [...response.data]
                }).catch((error) => {
                    console.error(error)
                    this.$notify.error('Nepodařilo se načíst seznam IZ.')
                }).finally(() => {
                    this.$nextTick(() => {
                        this.brokersLoading = false
                    })
                })
        },
        onEdit (row) {
            if (this.editedUser) {
                this.editedUser = null
            }
            this.$nextTick(() => {
                this.editedUser = row
            })
        },
        onImpersonate (row) {
            this.$api.users.impersonate(row._id)
                .then(() => {
                    window.location = '/'
                }).catch((error) => {
                    console.error(error)
                    this.$notify.error('Nepodařilo se provést impersonaci')
                })
        },
        onRemove (row) {
            this.usersLoading = true
            this.$api.users.delete(row._id)
                .then(() => {
                    this.$notify.success('Uživatel byl smazán.')
                }).catch((error) => {
                    console.error(error)
                    this.$notify.error('Uživatele se nepodařilo smazat.')
                }).finally(() => {
                    this.loadUsers()
                })
        }
    }
}
</script>

<i18n>
{
    "cz": {
        "users": {
            "heading": "Správa uživatelů aplikace",
            "subtitle": "V této sekci můžete administrovat všechny uživatele s přístupem k portálu."
        }
    },
    "en": {
        "users": {}
    }
}
</i18n>
