<template>
    <div class="p-4 bg-white shadow rounded-sm">
        <data-table
            class="table-sm-font"
            :auto-update="false"
            :header="header"
            :loading="loading"
            :data="dataset"
            :buttons="buttons"
            lang="cs_CZ"
            :paging="true"
            :responsive="true"
            @edit="onEdit"
            @remove="onRemove"
            @impersonate="onImpersonate"
        />
    </div>
</template>

<script>
export default {
    name: 'UsersDataTable',
    props: {
        dataset: {
            type: Array,
            required: false,
            default: () => []
        },
        loading: {
            type: Boolean,
            required: false,
            default: false
        },
        brokers: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    computed: {
        header () {
            return [
                {
                    text: 'Jméno',
                    data: 'firstName',
                    sortable: true,
                    filterable: true
                },
                {
                    text: 'Příjmení',
                    data: 'lastName',
                    sortable: true,
                    filterable: true
                },
                {
                    text: 'Email',
                    data: 'email',
                    sortable: true,
                    filterable: true
                },
                {
                    text: 'Telefon',
                    data: 'phoneNumber',
                    sortable: true,
                    filterable: true
                },
                {
                    text: 'Vypnutý',
                    data: 'disabled',
                    sortable: true,
                    filterable: true,
                    format (value) {
                        return (value) ? 'Ano' : 'Ne'
                    }
                },
                {
                    text: 'Role',
                    data: 'roles',
                    sortable: true,
                    filterable: true,
                    format (value) {
                        return Array.isArray(value) ? value.join(', ') : value
                    }
                },
                {
                    text: 'IZDocs ID',
                    data: 'izdocsCompanyId',
                    sortable: true,
                    filterable: true,
                    format: this.formatIZ
                }
            ]
        },
        buttons () {
            return [
                {
                    event: 'impersonate',
                    variant: 'warning',
                    text: 'Impersonovat',
                    visibleIf (row) {
                        return !row.row.roles.includes('admin')
                    }
                },
                {
                    event: 'edit',
                    variant: 'primary',
                    text: 'Upravit'
                },
                {
                    event: 'remove',
                    variant: 'danger',
                    text: 'Smazat',
                    confirm: true,
                    confirmText: 'Opravdu smazat?'
                }
            ]
        }
    },
    methods: {
        formatIZ (value) {
            if (!value) {
                return value
            }
            const iz = this.brokers.filter(item => item.value === value)
            if (iz.length > 0) {
                return iz[0].text
            }
            return value
        },
        onEdit (row) {
            this.$emit('edit', row)
        },
        onRemove (row) {
            this.$emit('remove', row)
        },
        onImpersonate (row) {
            this.$emit('impersonate', row)
        }
    }
}
</script>
